import React from 'react';
import { Outlet } from 'react-router-dom';
import { Box, CssBaseline } from '@mui/material';
import TopNavbar from '../../components/TopNavbar/TopNavbar';

const MainPage: React.FC = () => {
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <TopNavbar />
      <Box
        sx={{
          marginTop: '8vh',
          width: '100%',
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};
export default MainPage;
