import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AppContext } from './lib/contextLib';
import { Box } from '@mui/material';
import MainPage from './pages/MainPage/MainPage';
import ProjectPage from './pages/ProjectPage/ProjectPage';
import ValuetreePage from './pages/ValuetreePage/ValuetreePage';
import { ProjectAllData } from './types';

function App() {
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [project, setProject] = useState<ProjectAllData | null>(null);

  return (
    <Box>
      <AppContext.Provider
        value={{
          openDrawer,
          project,
          setOpenDrawer,
          setProject,
        }}
      >
        <Router>
          <Routes>
            <Route path='/' element={<MainPage />}>
              <Route
                path='/api/projects/:projectid'
                element={<ProjectPage />}
              />
              <Route
                path='/api/projects/:projectid/:valuetreeid'
                element={<ValuetreePage />}
              />
            </Route>
          </Routes>
        </Router>
      </AppContext.Provider>
    </Box>
  );
}

export default App;
