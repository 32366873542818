import React, { useEffect, useState } from 'react';
import { API } from 'aws-amplify';
import { useParams, Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { useAppContext } from '../../lib/contextLib';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  IconButton,
} from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Valuetree } from '../../types';
import './projectPage.css';

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 700,
  },
});

const ProjectPage: React.FC = () => {
  const [sortByDate, setSortByDate] = useState<boolean>(false);
  const [sortByName, setSortByName] = useState<boolean>(false);
  const { project, setProject } = useAppContext();
  const { projectid } = useParams();

  useEffect(() => {
    const loadPublicProject = async () => {
      const getProjectAllData = projectid && (await fetchProject(projectid));
      setProject(getProjectAllData);
    };

    loadPublicProject();
  }, [projectid]);

  const fetchProject = async (projectid: string) => {
    return API.get('public', `/api/projects/${projectid}`, {});
  };

  const handleSortValuetreesByDate = () => {
    setSortByDate(!sortByDate);
    setSortByName(false);
    project.publishedProjectValuetrees.sort((a: Valuetree, b: Valuetree) => {
      if (sortByDate) {
        return a.lastModified < b.lastModified
          ? -1
          : a.lastModified > b.lastModified
          ? 1
          : 0;
      } else {
        return a.lastModified > b.lastModified
          ? -1
          : a.lastModified < b.lastModified
          ? 1
          : 0;
      }
    });
  };

  const handleSortValuetreesByName = () => {
    setSortByName(!sortByName);
    setSortByDate(false);
    project.publishedProjectValuetrees.sort((a: Valuetree, b: Valuetree) => {
      if (sortByName) {
        return a.valueTreeName < b.valueTreeName
          ? -1
          : a.valueTreeName > b.valueTreeName
          ? 1
          : 0;
      } else {
        return a.valueTreeName > b.valueTreeName
          ? -1
          : a.valueTreeName < b.valueTreeName
          ? 1
          : 0;
      }
    });
  };

  return (
    <Box
      className='projectPageDiv'
      sx={{
        width: '80%',
        padding: '20px 100px 100px',
        margin: '10px auto',
        minHeight: '95vh',
      }}
    >
      <h1>{project && project.projectMetaData.projectName}</h1>
      <Box sx={{ maxWidth: '800px', marginBottom: '40px' }}>
        <Typography>
          {project && project.projectMetaData.description}
        </Typography>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width='35%' sx={{ fontWeight: 'bold' }}>
                Tree
                <IconButton
                  onClick={handleSortValuetreesByName}
                  sx={{ marginLeft: '10px' }}
                >
                  {sortByName ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
                </IconButton>
              </TableCell>
              <TableCell width='40%' sx={{ fontWeight: 'bold' }}>
                Description
              </TableCell>
              <TableCell align='center' width='25%' sx={{ fontWeight: 'bold' }}>
                Last modified
                <IconButton
                  onClick={handleSortValuetreesByDate}
                  sx={{ marginLeft: '10px' }}
                >
                  {sortByDate ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
                </IconButton>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {project &&
              project.publishedProjectValuetrees.map((valuetree: Valuetree) => {
                return (
                  <TableRow key={valuetree.id}>
                    <TableCell>
                      <Link to={`/api/projects/${projectid}/${valuetree.id}`}>
                        {valuetree.valueTreeName}
                      </Link>
                    </TableCell>
                    <TableCell>
                      {valuetree.description.length > 80 ? (
                        <CustomWidthTooltip
                          title={<h2>{valuetree.description}</h2>}
                        >
                          <Box>
                            {valuetree.description.length > 80
                              ? `${valuetree.description.slice(0, 80)}...`
                              : valuetree.description}
                          </Box>
                        </CustomWidthTooltip>
                      ) : (
                        <Box>
                          {valuetree.description.length > 80
                            ? `${valuetree.description.slice(0, 80)}...`
                            : valuetree.description}
                        </Box>
                      )}
                    </TableCell>
                    <TableCell align='center'>
                      {valuetree.lastModified}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
export default ProjectPage;
