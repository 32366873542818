import React, { useEffect, useState } from 'react';
import { API } from 'aws-amplify';
import { styled } from '@mui/material/styles';
import { ValueTree } from '@spatineo/react-valuetree-component';
import { useParams } from 'react-router-dom';
import { useAppContext } from '../../lib/contextLib';
import {
  Box,
  CircularProgress,
  Collapse,
  IconButton,
  Typography,
  ToggleButton,
  Checkbox,
  Tooltip,
} from '@mui/material';
import { Valuetree, Node } from '../../types';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import './valuetreePage.css';
import SideMenuDrawer from '../../components/SideMenuDrawer/SideMenuDrawer';

const drawerWidth = 240;

const layerCount = [0, 1, 2, 3, 4, 5]

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

const ValuetreePage: React.FC = () => {
  const [fullScreen, setFullScreen] = useState<boolean>(false);
  const [valuetree, setValuetree] = useState<Valuetree | null>(null);
  const [openTreeDetails, setOpenTreeDetails] = useState<boolean>(false);
  const [openHideNode, setOpenHideNode] = useState<boolean>(false);
  const [hiddenNodes, setHiddenNodes] = React.useState<Array<string>>([]);
  const [hiddenLayers, setHiddenLayers] = React.useState<Array<number>>([]);
  const [activeClass, setActiveClass] = React.useState<Array<number>>([]);
  const [graphData, setGraphData] = useState({
    nodes: [],
    links: [],
  });

  const { openDrawer, setOpenDrawer } = useAppContext();
  const { projectid, valuetreeid } = useParams();

  const classChecker = (layerNumber: number) => {
    if (activeClass.includes(layerNumber)) {
      const newLayer = activeClass.filter((layer) => layer !== layerNumber);
      setActiveClass(newLayer);
    } else {
      setActiveClass([...activeClass, layerNumber]);
    }
  };

  const handleAddOrRemoveLayer = (layerNumber: number) => {
    if (hiddenLayers.includes(layerNumber)) {
      const newLayer = hiddenLayers.filter((layer) => layer !== layerNumber);
      setHiddenLayers(newLayer);
    } else {
      setHiddenLayers([...hiddenLayers, layerNumber]);
    }
  };

  const handleHideNodeCheckox = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.checked) {
      setHiddenNodes([...hiddenNodes, event.target.value]);
    }
    if (!event.target.checked) {
      const removedNodes = hiddenNodes.filter((f) => f !== event.target.value);
      setHiddenNodes(removedNodes);
    }
  };

  useEffect(() => {
    setOpenDrawer(true);
    const loadValuetree = async () => {
      try {
        const foundValuetree =
          projectid &&
          valuetreeid &&
          (await fetchValuetree(projectid, valuetreeid));
        setValuetree(foundValuetree);
      } catch (error) {
        console.error(error);
      }
    };

    loadValuetree();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectid, valuetreeid]);

  const fetchValuetree = async (projectid: string, valuetreeid: string) => {
    return API.get('public', `/api/projects/${projectid}/${valuetreeid}`, {});
  };

  useEffect(() => {
    let graph = {
      nodes: [] as any,
      links: [] as any,
    };
    if (valuetree === null) {
      return;
    }
    if (valuetree.links.length > 0) {
      valuetree.links.map((link) => {
        const newLink = {
          source: link.source,
          target: link.target,
          value: link.weight,
          color: link.color,
        };
        return graph.links.push(newLink);
      });
    }

    if (valuetree.nodes.length > 0) {
      valuetree.nodes.map((node) => {
        if (node.layer) {
          const newNode = {
            name: node.name,
            layer: node.layer,
            id: node.id,
            description: node.description,
            url: node.url,
          };
          return graph.nodes.push(newNode);
        }

        const newNode = {
          name: node.name,
          id: node.id,
          description: node.description,
          url: node.url,
        };
        return graph.nodes.push(newNode);
      });
    }

    setGraphData(graph);
  }, [valuetree, valuetreeid]);

  if (valuetree === null) {
    return <Box>Loading...</Box>;
  }

  const handleOpenTreeDetails = () => {
    setOpenTreeDetails(!openTreeDetails);
  };

  const handleOpenHideNode = () => {
    setOpenHideNode(!openHideNode);
  };


  const handleGoFullScreen = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setOpenDrawer(false);
    document.documentElement.requestFullscreen();
    setFullScreen(true);
  };

  const handleExitFullScreen = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    document.exitFullscreen();
    setFullScreen(false);
  };

  return (
    <Box 
      sx={{ 
        display: 'flex', 
        width: `calc(100% - ${drawerWidth}px)`,
        margin: 'auto'
        }}>
      <SideMenuDrawer drawerWidth={drawerWidth} />
      <Main open={openDrawer}>
        <Box sx={{ display: 'flex', width: '90%', margin: 'auto' }}>
          <Box className='valuetree-metadata'>
            <Box>
              <h1>{valuetree.valueTreeName}</h1>
            </Box>
            <React.Fragment>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <h5>Tree Details</h5>
                <IconButton
                  onClick={handleOpenTreeDetails}
                  size='small'
                >
                  {openTreeDetails ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                </IconButton>
              </Box>
              <Collapse in={openTreeDetails}>
                <Box
                  sx={{
                    display: 'flex',
                    border: '2px solid black',
                    marginBottom: '40px',
                  }}
                >
                  <Box className='details-first-column'>
                    <Box sx={{ display: 'flex' }}>
                      <p className='details-label'>Name: </p>
                      <p>{valuetree.valueTreeName}</p>
                    </Box>
                    <Box sx={{ display: 'flex' }}>
                      <p className='details-label'>Description: </p>
                      <p>{valuetree.description}</p>
                    </Box>
                  </Box>
                  <Box className='details-second-column'>
                    <Box sx={{ display: 'flex' }}>
                      <p className='details-label'>Last modified: </p>
                      <p>{valuetree.lastModified}</p>
                    </Box>
                    <Box sx={{ display: 'flex' }}>
                      <p className='details-label'>Created at: </p>
                      <p>{valuetree.createdAt}</p>
                    </Box>
                  </Box>
                </Box>
              </Collapse>
            </React.Fragment>
          </Box>
          <Box sx={{ width: '100%', textAlign: 'right', padding: '10px 10px' }}>
            {fullScreen ? (
              <Tooltip title='Exit full screen'>
                <IconButton onClick={handleExitFullScreen}>
                  <FullscreenExitIcon />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title='Full screen'>
                <IconButton
                  size='large'
                  sx={{ color: 'black' }}
                  onClick={handleGoFullScreen}
                >
                  {fullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </Box>
        <Box>
          {graphData.nodes.length > 0 && graphData.links.length > 0 ? (
            <Box>
              <Box sx={{width: '90%', padding: '2rem 4rem 0rem', margin: 'auto', border: '1px solid black' }}>
                <ValueTree
                  graphProp={graphData}
                  hiddenNodes={hiddenNodes}
                  clickToHideNode={undefined}
                  hiddenLayers={hiddenLayers}
                  zoomGraph={0}
                />
              </Box>
              <br/>
              <Box sx={{width: '90%', margin: 'auto auto -2rem auto', }}>
                <Typography>Click to hide layer:</Typography>
              </Box>
              <Box sx={{width: '100%', margin: 'auto', paddingLeft: '1rem'}}>
                {layerCount.map((layer, index: number) => {
                  return (
                    <ToggleButton
                    value={index}
                    key={layer} 
                    onClick={() => { 
                      handleAddOrRemoveLayer(index)
                      classChecker(index)
                    }}
                    sx={{
                      width: '30px',
                      height: '30px',
                      margin: '3rem',
                      border: 'none',
                      backgroundColor: 'lightsteelblue',
                      borderRadius: '50%',
                    }}
                    className={activeClass.includes(index) ? 'active-class-name': ''}
                    >
                      {index + 1}
                  </ToggleButton>
                )
                })}
              </Box>
                <Box sx={{ width: '90%',  margin: 'auto auto 2rem auto',}}>
                <Box >
                  <Typography>Click to hide node:  
                    <IconButton 
                      style={{ width: '30px', marginLeft: '10px' }}
                      onClick={handleOpenHideNode} size='small'>
                          {openHideNode ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                      </IconButton>
                    </Typography>
                    <br/>
                  </Box>
                  <Collapse in={openHideNode}>
                    <Box 
                      sx={{ 
                        columnCount: '3', 
                        columnFill: 'balance',
                        columnGap: '1rem', 
                      
                        width: '100%',
                        margin: 'auto',
                        }}>
                      {valuetree.nodes.map((node: Node) => {
                      return (
                        <Box
                          key={node.id}
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            alignItems: 'center',
                            }}>
                            <Checkbox size='small' value={node.id} onChange={handleHideNodeCheckox} />
                            <Box sx={{
                                verticalAlign: 'middle',
                                textTransform: 'capitalize',
                              }}>{node.name}
                            </Box>
                       </Box>
                     );
                   })}
                  </Box>
                </Collapse>
            </Box>
            </Box>
          ) : (
            <Box>
              <CircularProgress />
            </Box>
          )}
        </Box>
      </Main>
    </Box>
  );
};
export default ValuetreePage;
