import React, { useState } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { useAppContext } from '../../lib/contextLib';
import { Box, Drawer, Button, IconButton } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Valuetree } from '../../types';

interface SideMenuDrawerProps {
  drawerWidth: number;
}

const SideMenuDrawer: React.FC<SideMenuDrawerProps> = ({ drawerWidth }) => {
  const [sortByName, setSortByName] = useState<boolean>(false);
  const { openDrawer, setOpenDrawer, project } = useAppContext();
  const navigate = useNavigate();
  let { projectid, valuetreeid } = useParams();

  const handleBackToProject = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setOpenDrawer(!openDrawer);
    navigate(`/api/projects/${projectid}`);
  };

  const handleSortValuetreesByName = () => {
    setSortByName(!sortByName);
    project.publishedProjectValuetrees.sort((a: Valuetree, b: Valuetree) => {
      if (sortByName) {
        return a.valueTreeName < b.valueTreeName
          ? -1
          : a.valueTreeName > b.valueTreeName
          ? 1
          : 0;
      } else {
        return a.valueTreeName > b.valueTreeName
          ? -1
          : a.valueTreeName < b.valueTreeName
          ? 1
          : 0;
      }
    });
  };

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
          zIndex: 0,
          background: 'linear-gradient(white,#DDE1E2)',
          boxShadow:
            'rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px',
        },
      }}
      variant='persistent'
      anchor='left'
      open={openDrawer}
    >
      {valuetreeid && (
        <Box
          sx={{
            marginTop: '50px',
            paddingTop: '50px',
            textAlign: 'center',
            paddingBottom: '150px',
          }}
        >
          <Box>
            <Button
              startIcon={<ArrowBackIosNewIcon />}
              sx={{ margin: 'auto', backgroundColor: 'black' }}
              onClick={handleBackToProject}
              variant='contained'
            >
              Back to main
            </Button>
          </Box>
          {project && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
              }}
            >
              <h2>{project.projectMetaData.projectName}</h2>
              <IconButton
                onClick={handleSortValuetreesByName}
                sx={{
                  height: '30px',
                  width: '30px',
                  margin: 'auto 10px',
                }}
              >
                {sortByName ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
              </IconButton>
            </Box>
          )}
          <Box>
            {project &&
              project.publishedProjectValuetrees.map(
                (valuetree: Valuetree, index: number) => {
                  return (
                    <Box key={valuetree.id} sx={{ margin: 'auto' }}>
                      <Link
                        to={`/api/projects/${projectid}/${valuetree.id}`}
                        style={{ textDecoration: 'none' }}
                      >
                        <Button
                          sx={{
                            width: '90%',
                            color: '#4F4F4F',
                            borderTopLeftRadius: index === 0 ? '10px' : '0px',
                            borderTopRightRadius: index === 0 ? '10px' : '0px',
                            borderBottomLeftRadius:
                              index ===
                              project.publishedProjectValuetrees.length - 1
                                ? '10px'
                                : '0px',
                            borderBottomRightRadius:
                              index ===
                              project.publishedProjectValuetrees.length - 1
                                ? '10px'
                                : '0px',
                            padding: '10px 0px',
                            backgroundColor:
                              valuetree.id === valuetreeid
                                ? '#EAEEF5'
                                : 'white',
                            border:
                              valuetreeid === valuetree.id
                                ? '1px solid gray'
                                : '1px solid #EEEEEE',
                          }}
                          variant='outlined'
                        >
                          {valuetree.valueTreeName}
                        </Button>
                      </Link>
                    </Box>
                  );
                }
              )}
          </Box>
        </Box>
      )}
    </Drawer>
  );
};
export default SideMenuDrawer;
