import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Amplify } from 'aws-amplify';
import config from './config';

Amplify.configure({
  API: {
    endpoints: [
      {
        name: 'public',
        endpoint: config.publicApiGateway.URL,
        region: config.publicApiGateway.URL,
      },
    ],
  },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
