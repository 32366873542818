const config = {
  publicApiGateway: {
    URL: process.env.REACT_APP_PUBLIC_API_URL,
    REGION: process.env.REACT_APP_REGION,
  },
  stage: {
    STAGE_NAME: process.env.REACT_APP_STAGE_NAME,
  },
};

export default config;
