import React from 'react';
import { useAppContext } from '../../lib/contextLib';
import { useParams } from 'react-router-dom';
import { Box, Toolbar, IconButton, AppBar } from '@mui/material';
import SpatineoLogo from '../../assets/Spatineo-logo.png';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

const TopNavbar: React.FC = () => {
  const { openDrawer, setOpenDrawer } = useAppContext();
  const { valuetreeid } = useParams();

  const handleDrawerToggle = () => {
    setOpenDrawer(!openDrawer);
  };

  return (
    <AppBar position='fixed' sx={{ zIndex: 10, backgroundColor: '#000000' }}>
      <Toolbar sx={{ display: 'flex' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          {valuetreeid && (
            <IconButton
              onClick={handleDrawerToggle}
              size='large'
              edge='start'
              color='inherit'
              aria-label='open drawer'
            >
              {openDrawer ? <CloseIcon /> : <MenuIcon />}
            </IconButton>
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            marginLeft: 'auto',
            marginRight: '20px',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <img
            style={{ height: '40px', width: '40px' }}
            src={SpatineoLogo}
            alt='Spatineo logo'
          />
          <h2 style={{ color: 'white' }}>Spatineo Value Tree</h2>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
export default TopNavbar;
